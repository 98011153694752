<template>
  <div style="position:relative">
    <v-menu location="end">
      <template v-slot:activator="{ props: menuProps }">
        <v-btn
            icon="mdi-dots-vertical"
            v-bind="menuProps"
            class="float-right ma-2"
            variant="text"
            color="white"
            density="compact"
        />
      </template>
      <ClientOnly>
        <AddGameToCollectionMenu :item="item" @onShowMessage="showCollectionMessage"></AddGameToCollectionMenu>
      </ClientOnly>
    </v-menu>

    <NuxtLink :to="props.item.link" tag="div" :prefetch="false">
      <v-card class="card-container">
        <div class="date-overlay">
          {{ item.date }}
        </div>
        <div class="gradient-overlay">
          <v-card-item class="card-content">
            <v-card-title class="no-pointer-events">{{ item.title }}</v-card-title>
            <v-card-subtitle class="no-pointer-events">@{{ item.subtitle }}</v-card-subtitle>
            <v-card-subtitle class="no-pointer-events" v-if="item.link.indexOf('~') >= 0">unlisted</v-card-subtitle>
          </v-card-item>
        </div>
        <v-img :src="errSrc || filestack(props.item.image)" aspect-ratio="480/300" class="white--text align-end card-image" @error="handleImageError" :eager="eager">
        </v-img>
      </v-card>
      <SimmerCardEditLinks v-if="props.showProfileLinks" :link="item.link" :engine="item.engine"></SimmerCardEditLinks>
    </NuxtLink>
    <v-snackbar v-model="showToast" :timeout="5000" color="success">
      {{ toastMessage }}
    </v-snackbar>
  </div>
</template>

<script setup>
import SimmerCardEditLinks from "~/components/cards/SimmerCardEditLinks.vue";
import AddGameToCollectionMenu from "~/components/collections/AddGameToCollectionMenu.vue";

const BACKUP_IMG = '/images/480x300_transparent.webp';
const props = defineProps({
  item: {
    type: Object,
    default: () => ({
      image: BACKUP_IMG,
      subtitle: '',
      title: 'Untitled',
      date: '',
      link: '/',
      guid: '',
      engine: 'unity'
    }),
  },
  showProfileLinks: {
    type: Boolean,
    default: false,
  },
  eager:{
    type: Boolean,
    default: false,
  },
});
// const item = props.item;

const showToast = ref(false);
const toastMessage = ref('');

const showCollectionMessage = (message) => {
  showToast.value = true;
  toastMessage.value = message;
};
const filestack = (imgUrl) => {
  return imgUrl && imgUrl !== BACKUP_IMG?
      `https://process.filestackapi.com/AnP4G3pixRCyMaUo5jr8bz/cache=expiry:max/resize=width:480,height:300,fit:crop/output=format:webp/${imgUrl}`
      : BACKUP_IMG;
}
const errSrc = ref('');

const handleImageError = (e) => {
  if (e !== BACKUP_IMG){
    errSrc.value = BACKUP_IMG;
  }
};

</script>

<style scoped>

.float-right {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 2;
}


.gradient-overlay {
  height: 100%;
  width: 100%;
  position: absolute;
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.7) 100%);
  z-index: 1;
  pointer-events: none;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

.card-content {
  padding-bottom: 8px;
}

.card-image {
  transition: transform 0.2s ease-in-out;
  min-height: 120px;
}

.card-image:hover {
  transform: scale(1.1) rotate(-1.5deg);
}

:deep(.v-card-title),
:deep(.v-card-subtitle) {
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
}

.card-container{
  cursor:pointer
}

.date-overlay {
  position: absolute;
  top: -40px;
  left: 0;
  color: white;
  padding: 4px 8px;
  text-align: left;
  transition: transform 0.3s ease;
  z-index: 2;
  display: inline-block;
  background-color: rgba(0, 0, 0, 0.8);
  width: fit-content;
  border-bottom-right-radius: 4px;
}

.card-container:hover .date-overlay {
  transform: translateY(40px);
}


</style>