<template>
  <v-card max-width="400">
    <v-card-title>Add to Collection</v-card-title>
    <v-progress-linear v-if="loading" indeterminate color="primary"></v-progress-linear>
    <v-card-text v-else-if="collections.length === 0">You don't have any game collections. You can create one below, and then add this game to the collection.</v-card-text>
    <div v-else>
      <v-list density="compact">
        <v-list-item v-for="collection in collections" :key="collection.guid">
          <template v-slot:prepend>
            <v-btn
                v-if="inCollection(collection)"
                density="compact"
                color="error"
                icon="mdi-minus"
                class="mr-3"
                v-tooltip="'Remove from Collection'"
                @click.prevent="removeFromCollection(collection)"
            />
            <v-btn
                v-else
                density="compact"
                color="success"
                icon="mdi-plus"
                class="mr-3"
                v-tooltip="'Add to Collection'"
                @click.prevent="addToCollection(collection)"
            />
            <NuxtLink :prefetch="false" :to="editCollectionRoute(collection.friendlyUrl)">
              <v-btn density="compact" icon="mdi-pencil" class="mr-3" v-tooltip="'Edit Collection'"></v-btn>
            </NuxtLink>
          </template>
          <v-list-item-title>
            <NuxtLink :prefetch="false" :to="collectionRoute(collection.friendlyUrl)">
              {{ collection.name }}
            </NuxtLink>
          </v-list-item-title>
        </v-list-item>
      </v-list>
    </div>
    <v-card-actions>
      <v-spacer></v-spacer>
      <!-- todo lowpri: create a collection inline here instead of forcing them to go to profile -->
      <NuxtLink to="/my_collections"><v-btn density="comfortable">Create / Manage Collections</v-btn></NuxtLink>
      <v-spacer></v-spacer>
    </v-card-actions>
  </v-card>
</template>
<script setup>

import {dbRef} from "~/helpers/dbRef.js";
import {set} from "firebase/database";

const props = defineProps({
  item: {
    type: Object,
    required: true,
  },
});


const emit = defineEmits(['onShowMessage']);
const db = useDatabase();

const loading = ref(true);
const currentUser = useCurrentUser();
const collections = ref([]);

const collectionRoute = (friendlyUrl) => {
  return `/c/${friendlyUrl}`;
}

const editCollectionRoute = (friendlyUrl) => {
  return `/c/${friendlyUrl}/edit`;
}

const inCollection = (collection) => {
  return collection.items.includes(props.item.guid);
}

const saveCollection = async (collection) => {
  const serialized = Object.assign({}, collection.items.map(item => ({guid: item})));
  const itemsRef = dbRef(db, `collections/${collection.guid}/items`);
 try{
    await set(itemsRef, serialized);
 }
 catch (e) {
   console.error(e);
   return false;
 }
 return true;

}
const addToCollection = async (collection) => {
  collection.items.unshift(props.item.guid);
  const saved = await saveCollection(collection);

  if (saved) {
    emit('onShowMessage', `Added ${props.item.title} to ${collection.name}`);
  }
  else {
    // todo lowpri: make error red.
    emit('onShowMessage', `Failed to add ${props.item.title} to ${collection.name}`);
  }
}

const removeFromCollection = async (collection) => {
  // remove the item from the collection
  collection.items = collection.items.filter(item => item !== props.item.guid);
  const saved = await saveCollection(collection);
  if (saved) {
    emit('onShowMessage', `Removed ${props.item.title} from ${collection.name}`);
  }
  else {
    // todo lowpri: make error red.
    emit('onShowMessage', `Failed to remove ${props.item.title} from ${collection.name}`);
  }
}


onMounted(async () => {
  if (currentUser.value){
    collections.value = await useCollectionsByUid(currentUser.value.uid, true);
  }
  // else collections.value remains as [];
  loading.value = false;
});
</script>